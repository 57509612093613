import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—September 2009" />
	<NewsHeader />
    <h2>September 2009</h2>



	<h3 className="blogdate">
	Tuesday, September 15, 2009
	</h3>

	<h4 className="blogitemtitle">
	Upcoming Wellington concert - Oct 9
	</h4>
		<div className="blogitembody">
		<p>
		I'm putting on a Friday evening (October 9) concert at <span><i>Mojo
	Invincible on Willis Street</i></span>. I intend to have some fun by
	adding a musician or two to some songs, and I plan to video record some of
	the show (especially for you Canadians that I won't be seeing this year).
	The venue has a warm interior/ambience and offers a good selection of
	wine, beer, and light food. I'll update you as the details unfold!
	</p>
		</div>
				<div className="blogitemfooter">
		<p>posted by Jesse @ 15:44 +1200</p>
		</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
