import * as React from "react";
import { Link } from "gatsby";

import '../styles/components/_newsheader.scss';

const Header = () => (
	<div className="NewsHeader">
		<h2>Music news</h2>
		<p>
			You are either reading current events or old news, all related to my music and musical life.<br />
			<Link to="/news/">Return to the news root/index</Link> to choose older months or to see the most current news.<br />
			If you'd like to subscribe to this news page, simply copy the URL of this page into your preferred news reader app.
		</p>
	</div>
);

export default Header;